import React from 'react';
import { useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';

const Outer = styled.footer`
  margin-top: 8rem;
  position: relative;
  font-size: 1.4rem;
  line-height: 22px;
  letter-spacing: 0;
  color: #ffffff;

  @media (min-width: 641px) {
    margin-top: 11.6rem;
  }

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-image: url(data:image/svg+xml;utf8,%3Csvg%20width%3D%221440%22%20height%3D%22267%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M.338.842C167.455%2083.394%20647.342%2083.394%201440%20.842V267H.338C-166.78%207.01-166.78-81.71.338.842z%22%20fill%3D%22%2314788a%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
    background-size: 1440px 300px;
    background-repeat: no-repeat;
    @media (min-width: 641px) {
      height: 200px;
      background-size: 1440px 267px;
    }
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 641px) {
    justify-content: space-between;
  }
`;

const FooterBrand = styled.div`
  margin-bottom: 2.4rem;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 2.4rem;

  @media (min-width: 641px) {
    justify-content: flex-start;
    flex: 50%;
  }
`;

const FooterSocial = styled.ul`
  flex: none;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 2.4rem;
  margin-left: 0;
  list-style: none;

  & li {
    margin-bottom: 0;
  }

  & li + li {
    margin-left: 1.6rem;
  }

  @media (min-width: 641px) {
    justify-content: flex-end;
    flex: 50%;
  }
`;

const FooterLinks = styled(FooterSocial)`
  @media (min-width: 641px) {
    order: 1;
    margin-bottom: 0;
  }
`;
const FooterCopyright = styled(FooterSocial)`
  margin-bottom: 0;

  @media (min-width: 641px) {
    justify-content: flex-start;
  }
`;

const Footer = () => {
    const { site } = useStaticQuery(graphql`
        query socialQuery {
            site {
                siteMetadata {
                    socialLinks {
                        url
                        label
                        icon
                    }
                }
            }
        }
    `)

    return (
        <Outer>
          <div className="container">
            <Content>
              <FooterBrand>
                <Link
                  to="/"
                  style={{
                    textDecoration: 'none',
                    fontSize: '3.6rem',
                    fontWeight: '600',
                    fontFamily: 'Frank Ruhl Libre,serif',
                    color: '#ffffff'
                  }}
                >
                  KTR
                </Link>
              </FooterBrand>
              
              <FooterLinks>
                <li>
                  <Link to="/projects" style={{ textDecoration: 'none', color: '#ffffff' }} >
                    Projects
                  </Link>
                </li>
              </FooterLinks>
              <FooterSocial>
      
                  {
                      site.siteMetadata.socialLinks.map((link, i) => {
                          return (
                              <li key={i}>
                                  <a href={link.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#ffffff' }}>
                                      <span className="screen-reader-text">{link.label}</span>
                                      <img src={link.icon} alt={link.label} style={{ width: '2rem' }} />
                                  </a>
                              </li>
                          )
                      })
                  }

              </FooterSocial>
              <FooterCopyright as="div">&copy; {new Date().getFullYear()} Kevin Reynolds, all rights reserved</FooterCopyright>
            </Content>
          </div>
        </Outer>
    )
}

export default Footer;

