import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';

import '../css/styles.css'
import Header from './header'
import Footer from './footer'

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 16px 48px #e7ebf6;
  background: #fff;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
    margin: 0 auto;
    padding: 3rem 1rem 1.45rem;
    flex: 1 1 auto;
`

const Layout = ({ children }) => {

    return (
        <Wrapper>
            <Header />
            <Main>{children}</Main>
            <Footer />
        </Wrapper>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
